var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "button",
    { staticClass: "floating btn-add", on: { click: _vm.click } },
    [
      _c("center", [
        _c("img", {
          staticClass: "d-none d-sm-block pc",
          style: { width: _vm.size + "px", height: _vm.size + "px" },
          attrs: { src: _vm.icon },
        }),
      ]),
      _vm._t("default", function () {
        return [_c("div", { staticClass: "remove-margin" })]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }